import { Injectable } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import { isDefined } from '@interacta-shared/util';
import { ICommunityTree } from '@modules/communities/models/communities.model';
import { HomeCommunityIndex } from '@modules/core/models/utility.model';
import {
    AttachmentCategoryType,
    AttachmentLayout,
} from '@modules/post/models/attachment/attachment.model';
import {
    DashboardLayout,
    DashboardView,
} from '@modules/post/models/post-list.model';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import {
    CacheMap,
    CommunityUI,
    ManageUI,
    UIState,
    WorkspaceUI,
} from '../models/ui-state.model';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class UIStateService implements IStateService<UIState> {
    readonly state: UIState;

    private communityTree$: Observable<ICommunityTree>;
    private communityIds$: Observable<string[]>;

    private communityUIDefaults = {
        dashboardView: DashboardView.Extended,
        dashboardLayout: DashboardLayout.Mosaic,
        attachmentLayout: AttachmentLayout.GRID,
        attachmentView: AttachmentCategoryType.MIXED,
    };

    constructor(
        stateService: StateService,
        private authService: AuthService,
    ) {
        this.state = stateService.uiState;

        this.communityTree$ = stateService.communitiesState.communityTree$.pipe(
            filter(isDefined),
        );

        this.communityIds$ = this.communityTree$.pipe(
            map((communityTree) => [
                'dashboard',
                ...communityTree.communityList.map((c) => String(c.id)),
            ]),
        );
    }

    initializeCommunitiesUI(): void {
        this.regenerateState<CommunityUI>(
            this.communityIds$,
            this.state.communitiesUI.onDataChange(),
            this.defaultItems((_) => this.communityUIDefaults),
            () => true,
        ).subscribe((state) => this.state.communitiesUI.updateData(state));
    }

    initializeWorkspaceUI(): void {
        const workspaceIds$ = this.communityTree$.pipe(
            map((communityTree) =>
                communityTree.workspaceList.map((w) => String(w.id)),
            ),
        );
        this.regenerateState<WorkspaceUI>(
            workspaceIds$,
            this.state.workspacesUI.onDataChange(),
            this.defaultItems((_) => ({ isAccordionOpen: true })),
            () => true,
        ).subscribe((state) => this.state.workspacesUI.updateData(state));
    }

    initialize(): void {
        this.state.initialize();
        this.initializeCommunitiesUI();
        this.initializeWorkspaceUI();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    flush(): void {}

    changeCommunityView(
        communityId: HomeCommunityIndex,
        viewType: DashboardView,
    ): void {
        if (this.authService.currentUserData()) {
            const currState = this.state.communitiesUI.staticData();
            let nextState: CacheMap<CommunityUI>;
            if (currState) {
                nextState = {
                    ...currState,
                    [communityId]: {
                        ...currState[communityId],
                        dashboardView: viewType,
                    },
                };
            } else {
                nextState = {
                    [communityId]: {
                        dashboardView: viewType,
                        dashboardLayout: DashboardLayout.Mosaic,
                        attachmentView: AttachmentCategoryType.MIXED,
                        attachmentLayout: AttachmentLayout.GRID,
                    },
                };
            }
            this.state.communitiesUI.updateData(nextState);
        }
    }

    changeCommunityLayout(
        communityId: HomeCommunityIndex,
        layout: DashboardLayout,
    ): void {
        if (this.authService.currentUserData()) {
            const currState = this.state.communitiesUI.staticData();
            let nextState: CacheMap<CommunityUI>;
            if (currState) {
                nextState = {
                    ...currState,
                    [communityId]: {
                        ...currState[communityId],
                        dashboardLayout: layout,
                    },
                };
            } else {
                nextState = {
                    [communityId]: {
                        dashboardView: DashboardView.Extended,
                        dashboardLayout: layout,
                        attachmentView: AttachmentCategoryType.MIXED,
                        attachmentLayout: AttachmentLayout.GRID,
                    },
                };
            }
            this.state.communitiesUI.updateData(nextState);
        }
    }

    changeAttachmentsLayout(
        attachmentLayout: AttachmentLayout,
        communityId: number,
    ): void {
        const currState = this.state.communitiesUI.staticData();
        let nextState: CacheMap<CommunityUI>;
        if (this.authService.currentUserData()) {
            if (currState) {
                nextState = {
                    ...currState,
                    [communityId]: {
                        ...currState[communityId],
                        attachmentLayout,
                    },
                };
                this.state.communitiesUI.updateData(nextState);
            }
        }
    }

    changeAttachmentView(
        attachmentView: AttachmentCategoryType,
        communityId: number,
    ): void {
        if (this.authService.currentUserData()) {
            const currState = this.state.communitiesUI.staticData();
            let nextState: CacheMap<CommunityUI>;
            if (currState) {
                nextState = {
                    ...currState,
                    [communityId]: {
                        ...currState[communityId],
                        attachmentView: attachmentView,
                    },
                };
                this.state.communitiesUI.updateData(nextState);
            }
        }
    }

    toggleWorkspaceOpen(workspaceId: number): void {
        if (this.authService.currentUserData()) {
            const currState = this.state.workspacesUI.staticData();
            const nextState: CacheMap<WorkspaceUI> = {
                ...currState,
                [workspaceId]: {
                    isAccordionOpen: !currState?.[workspaceId]?.isAccordionOpen,
                },
            };
            this.state.workspacesUI.updateData(nextState);
        }
    }

    openWorkspaceAccordion(workspaceId: number): void {
        if (this.authService.currentUserData()) {
            const currState = this.state.workspacesUI.staticData();
            const nextState: CacheMap<WorkspaceUI> = {
                ...currState,
                [workspaceId]: { isAccordionOpen: true },
            };
            this.state.workspacesUI.updateData(nextState);
        }
    }

    changeLeftMenuOpen(isOpen: boolean): void {
        if (this.authService.currentUserData()) {
            const currState = this.state.leftMenuUI.staticData();
            const nextState = { ...currState, isOpen };
            this.state.leftMenuUI.updateData(nextState);
        }
    }

    changeManageUI({
        draftsOpen,
        mentionsOpen,
        processesOpen,
        tasksOpen,
    }: Partial<ManageUI>): void {
        const currState =
            this.state.manageUI.staticData() ?? this.state.manageUIDefaults();
        const nextState = {
            draftsOpen: draftsOpen ?? currState.draftsOpen,
            mentionsOpen: mentionsOpen ?? currState.mentionsOpen,
            processesOpen: processesOpen ?? currState.processesOpen,
            tasksOpen: tasksOpen ?? currState.tasksOpen,
        };
        this.state.manageUI.updateData(nextState);
    }

    private defaultItems<T>(getDefault: (id: string) => T) {
        return (ids: string[]) => {
            const state: CacheMap<T> = {};
            for (const id of ids) {
                state[id] = getDefault(id);
            }
            return state;
        };
    }

    /**
     * We regenerate the state in order to drop possible workspaces
     * which we can't access anymore
     */
    private regenerateState<T>(
        source$: Observable<string[]>,
        cache$: Observable<CacheMap<T> | undefined>,
        getDefaultState: (source: string[]) => CacheMap<T>,
        isValueAdmissible: (value: T) => boolean,
    ): Observable<CacheMap<T>> {
        return source$.pipe(
            withLatestFrom(cache$),
            map(([ids, cachedState]) => {
                const defaultState = getDefaultState(ids);
                if (!cachedState || Object.keys(cachedState).length === 0) {
                    return defaultState;
                }
                const regeneratedState: CacheMap<T> = {};
                for (const id of ids) {
                    regeneratedState[id] =
                        id in cachedState && isValueAdmissible(cachedState[id])
                            ? cachedState[id]
                            : defaultState[id];
                }
                return regeneratedState;
            }),
        );
    }
}
